<template>
    <authed-page>
        <router-view />
    </authed-page>
</template>

<script>
    import AuthedPage from "./AuthedPage";

    export default {
        name: "AuthedRoute",
        components: {AuthedPage},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {}
    }
</script>

<style scoped>
</style>