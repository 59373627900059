<template>
    <div class="box">
        <div class="loader9"></div>
    </div>

</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>
    .box {
        display: inline-block;
        height: 30px;
        width: 33.3%;
        position: relative;
        transition: all .2s ease;
    }

    /* -------------- loader9 -------------- */

    .loader9:before {
        content: "";
        position: absolute;
        top: 0;
        height: 8px;
        width: 8px;
        border-radius: 8px;
        -webkit-animation: loader9g 3s ease-in-out infinite;
        animation: loader9g 3s ease-in-out infinite;
    }

    .loader9 {
        position: relative;
        width: 8px;
        height: 8px;
        top: 46%;
        left: 46%;
        border-radius: 8px;
        background-color: rgb(51, 51, 51);
    }


    .loader9:after {
        content: "";
        position: absolute;
        top: 0;
        height: 8px;
        width: 8px;
        border-radius: 8px;
        -webkit-animation: loader9d 3s ease-in-out infinite;
        animation: loader9d 3s ease-in-out infinite;
    }

    @-webkit-keyframes loader9g {
        0% {
            left: -25px;
            background-color: rgba(51, 51, 51, .8);
        }
        50% {
            left: 0;
            background-color: rgba(51, 51, 51, .1);
        }
        100% {
            left: -25px;
            background-color: rgba(51, 51, 51, .8);
        }
    }

    @keyframes loader9g {
        0% {
            left: -25px;
            background-color: rgba(51, 51, 51, .8);
        }
        50% {
            left: 0;
            background-color: rgba(51, 51, 51, .1);
        }
        100% {
            left: -25px;
            background-color: rgba(51, 51, 51, .8);
        }
    }


    @-webkit-keyframes loader9d {
        0% {
            left: 25px;
            background-color: rgba(51, 51, 51, .8);
        }
        50% {
            left: 0;
            background-color: rgba(51, 51, 51, .1);
        }
        100% {
            left: 25px;
            background-color: rgba(51, 51, 51, .8);
        }
    }

    @keyframes loader9d {
        0% {
            left: 25px;
            background-color: rgba(51, 51, 51, .8);
        }
        50% {
            left: 0;
            background-color: rgba(51, 51, 51, .1);
        }
        100% {
            left: 25px;
            background-color: rgba(51, 51, 51, .8);
        }
    }

</style>