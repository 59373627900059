<template>
    <div class="ast-menus">

        <perfect-scrollbar>
            <a-menu
                    class="left-nav"
                    :openKeys.sync="openKeys"
                    v-model="selectedKeys"
                    @openChange="menuOpenChange"
                    @select="menuSelected"
                    mode="inline"

            >
                <template v-for="(item) in menus">

                    <template v-if="!item.children">
                        <a-menu-item :key=" item.route || item.name " @click="menuClicked(item)">
                            <div class="flex flex-left-center">
                            <span class="item-icon">
                                <img class="normal" :src="item.icons.normal" alt="">
                                <img class="highlight" :src="item.icons.highlight" alt="">
                            </span>
                                {{ item.name }}
                            </div>
                        </a-menu-item>
                    </template>
                    <template v-else>

                        <a-sub-menu :key=" item.route || item.name ">

                            <div slot="title" class="flex flex-left-center">
                            <span class="item-icon">
                                <img class="normal" :src="item.icons.normal" alt="">
                                <img class="highlight" :src="item.icons.highlight" alt="">
                            </span>
                                {{ item.name }}
                            </div>

                            <template v-for="(child) in item.children">

                                <template v-if="!child.children">
                                    <a-menu-item :key=" child.route || child.name " @click="menuClicked(child)">
                                        {{ child.name }}
                                    </a-menu-item>
                                </template>
                                <template v-else>

                                    <a-sub-menu :key=" child.route || child.name " :title=" child.name ">

                                        <template v-for=" subChild in child.children ">
                                            <a-menu-item :key=" subChild.route || subChild.name "
                                                         @click="menuClicked(subChild)">
                                                <span>{{ subChild.name }}</span>
                                            </a-menu-item>
                                        </template>

                                    </a-sub-menu>

                                </template>

                            </template>


                        </a-sub-menu>

                    </template>


                </template>

            </a-menu>
        </perfect-scrollbar>
    </div>
</template>

<script>
export default {
    name: "AstMenu",
    props: {
        menus: {
            type: Array,
            default() {
                return []
            }
        },
        current: String,
    },
    watch: {
        current() {
            this.expNav()
        }
    },
    mounted() {
        this.expNav()
    },
    data() {
        return {
            openKeys: [],
            selectedKeys: [],
        }
    },
    methods: {
        expNav() {
            if (!this.menus) return;
            this.selectedKeys = [];
            this.openKeys = [];

            this.expKeys(this.menus)
        },
        menuSelected() {

        },
        expKeys(menus) {

            let exit = false;

            menus.forEach(item => {

                let itemKey = item.route || item.name;

                if (itemKey === this.current) {

                    this.selectedKeys = [itemKey]

                    return exit = true;
                }

                if (item.children) {

                    if (this.expKeys(item.children)) {

                        this.openKeys.push(itemKey)

                        return exit = true;
                    }
                }

            })

            return exit;
        },
        menuOpenChange() {

        },
        menuClicked(item) {
            if (item.children) return;

            if (item.target === '_blank') {
                window.open(item.route)
            } else {
                this.$router.push(item.route)
            }
        },
    }
}
</script>

<style lang="less">

.ast-menus .left-nav {
    padding-top: 51px;
    padding-right: 32px;
    padding-left: 40px;
    width: 335px;
    height: 100vh;
    border-right: 1px solid #EEEEEE;
    background-color: white;

    .ant-menu-item {
        &:hover {
            color: #333333 !important;
        }
    }

    .ant-menu-submenu-title {
        &:hover {
            color: #333333 !important;
        }
    }

    > .ant-menu-submenu > .ant-menu-submenu-title {
        color: #333333;
    }

    .ant-menu-submenu-arrow {
        &:after, &:before {
            background: #333333 !important;
        }
    }

    .ant-menu-submenu, .ant-menu-item {

        color: #999999;

        .item-icon {
            line-height: 0;
            margin-right: 12px;

            .highlight {
                display: none;
            }

            img {
                width: 25px;
            }
        }

        &.ant-menu-item-selected {
            color: #333333 !important;

            .item-icon {
                .highlight {
                    display: inline;
                }

                .normal {
                    display: none;
                }
            }
        }

        &.ant-menu-submenu-selected {
            color: #333333 !important;
        }

    }


    .ant-menu-item-selected {
        background-color: #F5F5F5 !important;
        color: #333333 !important;

        &:after {
            border-right: 3px solid #333333;
        }
    }
}

</style>