import Vue from "vue";
import VueRouter from "vue-router";
import FullPage from "./layout/FullPage";
import AstRoute from "./layout/AstRoute";
import AuthedRoute from "./layout/AuthedRoute";

Vue.use(VueRouter)

const routes = [
    {
        path: '/ast/reg',
        component: () => import('./views/AST/Reg/Index')
    },
    {
        path: '/ast',
        component: AstRoute,
        children: [
            {
                path: 'pay/apply',
                component: () => import('./views/AST/Pay/Index')
            }, {
                path: 'members/orders',
                component: () => import('./views/AST/Member/Order')
            }, {
                path: 'goods/schemes',
                component: () => import('./views/AST/Goods/Scheme')
            }, {
                path: 'members/applies',
                component: () => import('./views/AST/Member/Apply')
            }, {
                path: 'members/applies/:id',
                component: () => import('./views/AST/Member/ApplyDetail')
            },
            {
                path: 'goods',
                component: () => import('./views/AST/Goods/Picker')
            }, {
                path: 'goods/my',
                component: () => import('./views/AST/Goods/My')
            },
            {
                path: 'members',
                component: () => import('./views/AST/Member/List')
            },
            {
                path: 'members/reports',
                component: () => import('./views/AST/Member/Report')
            },
            {
                path: 'paper/basic',
                component: () => import('./views/AST/Paper/Basic')
            },
            {
                path: 'paper/codes',
                component: () => import('./views/AST/Paper/Code')
            },
            {
                path: 'paper/detail/:id',
                component: () => import('./views/AST/Paper/Detail')
            },
            {
                path: 'paper/orders',
                component: () => import('./views/AST/Paper/Order')
            },
            {
                path: 'shop/guid',
                component: () => import('./views/AST/Shop/Guid')
            },
            {
                path: 'paper/lipstick',
                component: () => import('./views/AST/Paper/Lipstick')
            },
            {
                path: 'qas/create',
                component: () => import('./views/AST/Q&A/Detail')
            },
            {
                path: 'helpers',
                component: () => import('./views/AST/Helper/Index')
            },
            {
                path: 'helpers/:id',
                component: () => import('./views/AST/Helper/Detail')
            },
            {
                path: 'qas/:id/members',
                component: () => import('./views/AST/Q&A/Member')
            },
            {
                path: 'qas/:id',
                component: () => import('./views/AST/Q&A/Detail')
            },
            {
                path: 'qas',
                component: () => import('./views/AST/Q&A/List')
            },
            {
                path: 'members/report/detail',
                component: () => import('./views/AST/Member/ReportDetail')
            },
            {
                path: 'members/:id',
                component: () => import('./views/AST/Member/Detail')
            },
            {
                path: 'xcx/base',
                component: () => import('./views/AST/Xcx/Base')
            },
            {
                path: 'xcx/admin',
                component: () => import('./views/AST/Xcx/Admin')
            },
            {
                path: 'config/tools/lipstick',
                component: () => import('./views/AST/ColorTools/Lipstick')
            },
            {
                path: 'config/tools/cloth',
                component: () => import('./views/AST/ColorTools/Cloth')
            },
            {
                path: 'config/tools/color',
                component: () => import('./views/AST/ColorTools/Color')
            },
            {
                path: 'xcx/auth',
                component: () => import('./views/AST/Xcx/Auth')
            },
            {
                path: 'xcx/balance',
                component: () => import('./views/AST/Xcx/Balance')
            },
            {
                path: 'xcx/trusteeship',
                component: () => import('./views/AST/Xcx/Trusteeship')
            },
            {
                path: 'xcx/item',
                component: () => import('./views/AST/Xcx/Item')
            },
            {
                path: 'xcx/push',
                component: () => import('./views/AST/Xcx/Push')
            },
            {
                path: 'serve/type',
                component: () => import('./views/AST/Serve/Type')
            },
            {
                path: 'serve/level',
                component: () => import('./views/AST/Serve/Level')
            },
            {
                path: 'serve/:module',
                component: () => import('./views/AST/Serve/Course')
            },
            {
                path: 'wechat/video',
                component: () => import('./views/AST/Wechat/Video')
            },
            {
                path: 'shop',
                component: () => import('./views/AST/Shop/Index')
            },
            {
                path: 'cases',
                component: () => import('./views/AST/Serve/Cases')
            }, {
                path: 'cases/tags',
                component: () => import('./views/AST/Serve/CasesTags')
            },
            {
                path: 'shop/goods',
                component: () => import('./views/AST/Shop/Goods')
            },
            {
                path: 'shop/scheme',
                component: () => import('./views/AST/Shop/Scheme')
            },
            {
                path: 'shop/upload',
                component: () => import('./views/AST/Shop/Upload')
            }, {
                path: 'shop/batch-upload',
                component: () => import('./views/AST/Shop/GoodsBatchUploader')
            },
            {
                path: 'shop/goods/review',
                component: () => import('./views/AST/Shop/GoodsReview')
            },
            {
                path: 'shop/goods/sync',
                component: () => import('./views/AST/Shop/GoodsSync')
            },
            {
                path: 'shop/cashier',
                component: () => import('./views/AST/Cashier/Index')
            },
            {
                path: 'shop/bill',
                component: () => import('./views/AST/Cashier/Bill')
            },
            {
                path: 'shop/exhibits',
                component: () => import('./views/AST/Shop/ExhibitList')
            },
            {
                path: 'shop/exhibits/upload',
                component: () => import('./views/AST/Shop/ExhibitUpload')
            },
            {
                path: 'shop/bill/income/:id',
                component: () => import('./views/AST/Cashier/Income')
            },
            {
                path: 'shop/bill/expenditure/:id',
                component: () => import('./views/AST/Cashier/Expenditure')
            },
            {
                path: 'shop/cashier/sharing/:id',
                component: () => import('./views/AST/Cashier/Sharing')
            },
            {
                path: 'shop/recommend/:id?',
                component: () => import('./views/AST/Cashier/Recommend')
            },
            {
                path: 'shop/goods/:id',
                component: () => import('./views/AST/Shop/GoodsDetail')
            },
        ],
    },
    {
        path: '/dps',
        component: AuthedRoute,
        children: [
            {
                path: 'deck',
                name: 'deck',
                component: () => import('./views/DPS/Deck/Deck')
            },
            {
                path: 'collection',
                name: 'collection',
                component: () => import('./views/DPS/Collection/Collection')
            },
            {
                path: '',
                name: 'shop',
                component: () => import('./views/DPS/Shop/Shop')
            }, {
                path: 'outfits',
                name: 'outfits',
                component: () => import('./views/DPS/Outfit/Outfit')
            },
            {
                path: 'outfits/:id',
                name: 'outfitsDetail',
                component: () => import('./views/DPS/Outfit/Detail')
            }
        ]
    },
    {
        path: '',
        component: FullPage,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('./views/DPS/Login')
            },
            {
                path: '/login/admin',
                component: () => import('./views/YH/LoginAdmin')
            },
            // {
            //     path: '/gender',
            //     component: () => import('./views/YH/GenderPicker')
            // },
            {
                path: '/collections',
                component: () => import('./views/YH/Collection/Index')
            },
            {
                path: '/collections/:id',
                component: () => import('./views/YH/Collection/Detail')
            },
            {
                path: '/goods',
                component: () => import('./views/YH/Goods/Index')
            },
            {
                path: '/goods/:id',
                component: () => import('./views/YH/Goods/Detail')
            },
            {
                path: '/',
                component: () => import('./views/YH/Home')
            },
            {
                path: '/m/:account',
                component: () => import('./views/YH/Member/Detail')
            }
        ]
    },
    {
        path: '*',
        component: () => import('./views/NotFound')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router