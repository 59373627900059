<template>
    <a class="la" @click.stop="gotoURL" :target="target" :href="href">
        <slot/>
    </a>
</template>

<script>
    export default {
        name: "LA",
        props: {
            href: String,
            target: String,
        },
        methods: {
            gotoURL(e) {
                if (this.target === '_blank') return;

                e.preventDefault()

                this.$router.push(this.href)
            }
        }
    }
</script>

<style scoped>
    .la{
        color: inherit;
    }
</style>